import React,{Fragment} from 'react'
import {Link} from 'gatsby'
import { StaticImage } from "gatsby-plugin-image";
import Seo from "../../../components/seo/seo";
import AOS from "aos";
import logo from '../../../assets/img/virsat-logo-light.svg'

const ThankYouReg = () => {
  React.useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  return (
    <Fragment>
      <Seo
        title='Thank You | VIRSAT - Virtual Reality Safety Training'
        description='Thank You'
      />
      <div className="main-container thank-you-lp">
        <div className="thank-you-lp-bg">
          <StaticImage
            src='../../../assets/img/pages/newsletter/virsat-newsletter-background.jpg'
            alt='VIRSAT Thank You Backgorund'
            placeholder='blurred'
            objectFit
          />
        </div>
        <div className="row">
          <div className="column show-overflow">
            <h1 className="main-title secondary-color center">Thank you!</h1>
            <p className="light description center">Thank you for your registration!</p>
            <p className="light description center">
            You can join the webinar through the link sent to your registered email.
            </p>
            <p className="light description center">See you online!</p>

            <Link to="/" className="thank-you-logo">
              <img src={logo} alt="VIRSAT Logo" title="VIRSAT Logo"/>
            </Link>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default ThankYouReg
